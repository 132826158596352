import { useQuery } from '@apollo/client'
import { FIND_CURRENT_ADMIN } from '../gqls'

export const useAdmin = (params = {}) => {
    const { onCompleted = () => { }, onError = () => { }, fetchPolicy = 'network-only' } = params

    const { data, loading, error, refetch } = useQuery(FIND_CURRENT_ADMIN, {
        onCompleted,
        onError,
        fetchPolicy
    })

    const admin = data ? data.findCurrentAdmin : null

    return {
        loading,
        error,
        admin,
        refetch
    }
}
import styled from 'styled-components'
import { Form as AntForm, Input, Button, message, DatePicker } from 'antd'
import { useMutation, useQuery } from '@apollo/client'
import { useNavigate, useParams } from 'react-router-dom'

import Title from '../components/Title'

import { CREATE_ONE_COMPANY_BILLING, FIND_FIRST_COMPANY_BILLING, UPDATE_ONE_COMPANY_BILLING, UPDATE_ONE_COMPANY } from '../gqls'

const Form = styled(AntForm)`
    max-width: 600px;
`
const rules = {
    required: {
        required: true,
        message: 'Обязательное поле'
    }
}

const RegistrationPayment = () => {
    const [form] = Form.useForm()
    const { id } = useParams()
    const navigate = useNavigate()
    var isCreate = true;

    const [registerCompanyBillingMutation, { loading }] = useMutation(CREATE_ONE_COMPANY_BILLING, {
        onCompleted: () => {
            navigate(-1)
            message.success('Заявка принята')
        },
        onError: (error) => {
            console.log(error)
            message.error('Что то пошло не так, повторите попытку позже')
        }
    })

    const [updateOneCompanyBillingMutation, { loading: updateOneCompanyLoading }] = useMutation(UPDATE_ONE_COMPANY_BILLING, {
        onCompleted: () => {
            message.success('Данные обновлены')
        },
        onError: e => {
            message.error('Что то пошло не так, повторите попытку позже')
        }
    })

    const [updateOneCompanyMutation, {  }] = useMutation(UPDATE_ONE_COMPANY, {
        onCompleted: () => {
            message.success('Данные обновлены')
        },
        onError: e => {
            message.error('Что то пошло не так, повторите попытку позже')
        }
    })


    const { data: findFirstCompanyBilling } = useQuery(FIND_FIRST_COMPANY_BILLING, {
        variables: {
            where: {
                companyId: {
                    equals: id
                }
            }
        },
        onCompleted: ({ findFirstCompanyBilling }) => {
            console.log(findFirstCompanyBilling)
            if (findFirstCompanyBilling != null) {
                isCreate = false
                form.setFieldsValue({
                    fullName: findFirstCompanyBilling.fullName,
                    inn: findFirstCompanyBilling.inn,
                    kpp: findFirstCompanyBilling.kpp,
                    account: findFirstCompanyBilling.account,
                    bankName: findFirstCompanyBilling.bankName,
                    bik: findFirstCompanyBilling.bik,
                    ogrn: findFirstCompanyBilling.ogrn,
                    correspAcc: findFirstCompanyBilling.correspAcc,
                    companyId: id
                })
            } else {
                form.setFieldsValue({
                    companyId: id
                })
            }
        }
    })
    const findUniqueCompany = findFirstCompanyBilling?.findFirstCompanyBilling

    const handleSubmit = async (values) => {
        console.log(isCreate);
        if (isCreate) {
            await registerCompanyBillingMutation({
                variables: {
                    where: {
                        id
                    },
                    data: {
                        ...values
                    }
                }
            })
            await updateOneCompanyMutation({
                variables: {
                    where: {
                        id
                    },
                    data: {
                        legal: {
                            set: true
                        },
                    }
                }
            });
        } else {
            await updateOneCompanyBillingMutation({
                variables: {
                    where: {
                        id: findUniqueCompany.id
                    },
                    data: {
                        fullName: {
                            set: values.fullName
                        },
                        inn: {
                            set: values.inn
                        },
                        ogrn: {
                            set: values.ogrn
                        },
                        kpp: {
                            set: values.kpp
                        },
                        account: {
                            set: values.account
                        },
                        bankName: {
                            set: values.bankName
                        },
                        bik: {
                            set: values.bik
                        },
                        correspAcc: {
                            set: values.correspAcc
                        }
                    }
                }
            })
        }
    }

    return (
        <>
            <Title text='Регистрация онлайн оплаты' />
            <Form form={form} onFinish={handleSubmit} layout='vertical'>
                <Form.Item
                    name='fullName'
                    label='Полное наименование организации'
                    rules={[rules.required]}
                >
                    <Input placeholder='Введите полное наименование организации' />
                </Form.Item>
                <Form.Item
                    name='inn'
                    label='ИНН'
                    rules={[rules.required]}
                >
                    <Input placeholder='Введите ИНН' />
                </Form.Item>
                <Form.Item
                    name='kpp'
                    label='КПП'
                    rules={[rules.required]}
                >
                    <Input placeholder='Введите КПП' />
                </Form.Item>
                <Form.Item
                    name='ogrn'
                    label='ОГРН'
                    rules={[rules.required]}
                >
                    <Input placeholder='Введите ОГРН' />
                </Form.Item>
                <Form.Item
                    name='account'
                    label='Расчетный счет'
                    rules={[rules.required]}
                >
                    <Input placeholder='Введите расчетный счет' />
                </Form.Item>
                <Form.Item
                    name='bankName'
                    label='Наименование банка'
                    rules={[rules.required]}
                >
                    <Input placeholder='Введите наименование банка' />
                </Form.Item>
                <Form.Item
                    name='bik'
                    label='БИК'
                    rules={[rules.required]}
                >
                    <Input placeholder='Введите БИК' />
                </Form.Item>
                <Form.Item
                    name='correspAcc'
                    label='Номер кор. счета банка получателя платежа'
                    rules={[rules.required]}
                >
                    <Input placeholder='Введите номер кор. счета банка получателя платежа' />
                </Form.Item>
                <Form.Item
                    name='companyId'
                    label=''
                >
                    <Input type='hidden' />
                </Form.Item>

                <Button loading={loading} type='primary' htmlType='submit'>
                    Сохранить
                </Button>
            </Form>
        </>
    )
}

export default RegistrationPayment

import React, { useState } from 'react'

import styled from 'styled-components'
import { Form as AntForm, Button, message, Input, InputNumber, Select } from 'antd'
import { useMutation, useQuery } from '@apollo/client'
import { useNavigate } from 'react-router-dom'

import { CREATE_ONE_PROMOTION, FIND_MANY_OBJECT, FIND_MANY_TOUR, FIND_MANY_POST } from '../gqls'

const Form = styled(AntForm)`
    max-width: 600px;
`

const rules = {
    required: {
        required: true,
        message: 'Обязательное поле'
    }
}

const AddPromotion = () => {
    const navigate = useNavigate()
    const [form] = Form.useForm()

    const { data: findManyObjectData, loading: findManyObjectLoading, error: findManyObjectError } = useQuery(FIND_MANY_OBJECT, {
        fetchPolicy: 'network-only'
    })

    const { data: findManyTourData, loading: findManyTourLoading, error: findManyTourError } = useQuery(FIND_MANY_TOUR, {
        fetchPolicy: 'network-only'
    })

    const { data: findManyPostData, loading: findManyPostLoading, error: findManyPostError } = useQuery(FIND_MANY_POST, {
        fetchPolicy: 'network-only'
    })

    const [createOnePromotionMutation, { loading: createOnePromotionLoading }] = useMutation(CREATE_ONE_PROMOTION, {
        onCompleted: () => {
            form.resetFields()
            message.success('Подборка добавлена')
            navigate(-1)
        },
        onError: e => {
            message.error('Что то пошло не так, повторите попытку позже')
        }
    })

    if (findManyObjectLoading || findManyTourLoading || findManyPostLoading || findManyObjectError || findManyTourError || findManyPostError) return null

    const { findManyObject } = findManyObjectData
    const { findManyTour } = findManyTourData
    const { findManyPost } = findManyPostData

    const objects = findManyObject.map(object => ({
        label: object.name,
        value: object.id
    }))

    const tours = findManyTour.map(object => ({
        label: object.name,
        value: object.id
    }))

    const posts = findManyPost.map(object => ({
        label: object.user,
        value: object.id
    }))

    const handleSubmit = async (values) => {
        await createOnePromotionMutation({
            variables: {
                data: {
                    name: values.name,
                    weight: values.weight || 0,
                    objects: values.objects,
                    tours: values.tours,
                    posts: values.posts
                }
            }
        })
    }

    return (
        <>
            <Form form={form} onFinish={handleSubmit} layout='vertical'>
                <Form.Item rules={[rules.required]} name='name' label='Название подборки'>
                    <Input placeholder='Введите название' />
                </Form.Item>
                <Form.Item name='weight' label='Вес'>
                    <InputNumber style={{ width: '100%' }} placeholder='Введите вес' />
                </Form.Item>
                <Form.Item name='objects' label='Обьекты'>
                    <Select
                        mode='multiple'
                        placeholder='Выберите обьекты'
                        optionFilterProp='label'
                        options={objects}
                    />
                </Form.Item>
                <Form.Item name='tours' label='Туры'>
                    <Select
                        mode='multiple'
                        placeholder='Выберите туры'
                        optionFilterProp='label'
                        options={tours}
                    />
                </Form.Item>
                <Form.Item name='posts' label='Посты'>
                    <Select
                        mode='multiple'
                        placeholder='Выберите посты'
                        optionFilterProp='label'
                        options={posts}
                    />
                </Form.Item>
                <Button loading={createOnePromotionLoading} type='primary' htmlType='submit'>
                    Добавить
                </Button>
            </Form>
        </>
    )
}

export default AddPromotion

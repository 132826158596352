import React, { useState } from 'react'

import styled from 'styled-components'
import { Form as AntForm, Button, message, Input, InputNumber } from 'antd'
import { useMutation } from '@apollo/client'
import { useNavigate, useParams } from 'react-router-dom'
import 'dayjs/locale/ru'

import { CREATE_ONE_ADMIN_TG_BOT } from '../gqls'


const Form = styled(AntForm)`
    max-width: 600px;
`

const rules = {
    required: {
        required: true,
        message: 'Обязательное поле'
    }
}

const AddCompanyAdminTgBot = () => {
    const navigate = useNavigate()
    const [form] = Form.useForm()
    const [randomNumber, setRandomNumber] = useState(0)

    const { id } = useParams()

    const [createOneAdminTgBotMutation, { loading: createOneAdminTgBotLoading }] = useMutation(CREATE_ONE_ADMIN_TG_BOT, {
        onCompleted: () => {
            form.resetFields()
            message.success('Пост добавлен')
            navigate(-1)
        },
        onError: e => {
            message.error('Что то пошло не так, повторите попытку позже')
        }
    })

    const handleSubmit = async (values) => {
        await createOneAdminTgBotMutation({
            variables: {
                data: {
                    phone: values.phone,
                    code: values.code,
                    companyId: id
                }
            }
        })
    }
    function generateRandomNumber() {
        const min = 100000; 
        const max = 999999; 
        const randomNumber = 
            Math.floor(Math.random() * (max - min + 1)) + min; 
            return ""+randomNumber
    }

    form.setFieldsValue({
        code: generateRandomNumber()
    })

    return (
        <>
            <Form form={form} onFinish={handleSubmit} layout='vertical'>
                <Form.Item rules={[rules.required]} name='phone' label='Номер телефона'>
                    <Input placeholder='Введите номер телефона' />
                </Form.Item>
                <Form.Item rules={[rules.required]} name='code' label='Код авторизации' >
                    <InputNumber style={{ width: '100%' }} placeholder='Код авторизации' disabled />
                </Form.Item>
                <Button loading={createOneAdminTgBotLoading} type='primary' htmlType='submit'>
                    Добавить
                </Button>
            </Form>
        </>
    )
}

export default AddCompanyAdminTgBot

import React, { useState } from 'react'

import styled from 'styled-components'
import { Form as AntForm, Button, message, Input, Popconfirm, Upload } from 'antd'
import { useMutation, useQuery } from '@apollo/client'
import { useParams, useNavigate } from 'react-router-dom'

import { UPDATE_ONE_POST, FIND_UNIQUE_POST, DELETE_ONE_POST } from '../gqls'

const Form = styled(AntForm)`
    max-width: 600px;
`

const { TextArea } = Input

const HOST_NAME = process.env.REACT_APP_HOST_NAME
const Post = () => {
    const navigate = useNavigate()
    const [form] = Form.useForm()
    const { id } = useParams()
    const [images, setImages] = useState([])

    useQuery(FIND_UNIQUE_POST, {
        variables: {
            where: {
                id
            }
        },
        onCompleted: ({ findUniquePost }) => {
            form.setFieldsValue({
                postId: findUniquePost.postId,
                user: findUniquePost.user,
                text: findUniquePost.text,
            })
            setImages([{
                uid: findUniquePost.id,
                name: findUniquePost.image,
                status: 'done',
                url:  HOST_NAME + `uploads/${findUniquePost.image}`,
                response: {
                    filename: findUniquePost.image
                }
            }])
        }
    })

    const [updateOnePostMutation, { loading: updateOnePostLoading }] = useMutation(UPDATE_ONE_POST, {
        onCompleted: () => {
            message.success('Данные обновлены')
        },
        onError: e => {
            message.error('Что то пошло не так, повторите попытку позже')
        }
    })

    const [deleteOnePostMutation] = useMutation(DELETE_ONE_POST, {
        onCompleted: () => {
            message.success('Пост удален')
            navigate(-1)
        },
        onError: (err) => {
            console.error(err)
            message.error('Не удалось удалить')
        },
    })

    const handleSubmit = async (values) => {
        let postId
        if (values.url) {
            const url = new URL(values.url)
            postId = url.pathname.split('/')[2]
        }
        const files = images.map(object => object.response.filename) || ''

        await updateOnePostMutation({
            variables: {
                where: {
                    id
                },
                data: {
                    postId: postId ? {
                        set: postId
                    } : undefined,
                    user: {
                        set: values.user
                    },
                    text: {
                        set: values.text
                    },
                    image: {
                        set: files[0] || ''
                    }
                }
            }
        })
    }

    const onDelete = async () => {
        if (id) {
            await deleteOnePostMutation({
                variables: {
                    where: {
                        id
                    }
                }
            })
        }
    }

    const onPreview = async (file) => {
        const name = file.response.filename
        window.open(`${HOST_NAME}uploads/${name}`, '_blank')
    }

    return (
        <>
            <Form form={form} onFinish={handleSubmit} layout='vertical'>
                <Form.Item name='postId' label='Идентификатор поста'>
                    <Input disabled placeholder='Идентификатор поста' />
                </Form.Item>
                <Form.Item name='url' label='Заменить ссылку поста instagram'>
                    <Input placeholder='Введите ссылку поста instagram' />
                </Form.Item>
                <Form.Item name='user' label='Автор'>
                    <Input placeholder='Введите автора' />
                </Form.Item>
                <Form.Item name='text' label='Описание'>
                    <TextArea placeholder='Введите описание' />
                </Form.Item>
                <Form.Item label='Изображение' >
                    <Upload
                        action={HOST_NAME + 'upload'}
                        listType='picture-card'
                        fileList={images}
                        onChange={({ fileList }) => {
                            setImages(fileList)
                        }}
                        onPreview={onPreview}
                    >
                        {
                            images.length === 0 ? '+ Загрузить' : null
                        }
                    </Upload>
                </Form.Item>
                <Button loading={updateOnePostLoading} type='primary' htmlType='submit'>
                    Сохранить
                </Button>
                <Popconfirm title='Удалить?' onConfirm={onDelete}>
                    <Button type='primary' danger style={{ marginLeft: 5 }}>
                        Удалить
                    </Button>
                </Popconfirm>
            </Form>
        </>
    )
}

export default Post

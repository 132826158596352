import React, { useState } from 'react'

import styled from 'styled-components'
import { Form as AntForm, Button, message, Input, Upload, InputNumber, Select, Popconfirm, Switch } from 'antd'
import { UploadOutlined } from '@ant-design/icons'
import { useQuery, useMutation } from '@apollo/client'
import { useParams, useNavigate } from 'react-router-dom'
import IMask from 'imask'
import ReactQuill from 'react-quill'
import { DndContext, PointerSensor, useSensor } from '@dnd-kit/core'
import { arrayMove, SortableContext, useSortable, verticalListSortingStrategy } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'

import { FIND_UNIQUE_TOUR, UPDATE_ONE_TOUR, DELETE_ONE_TOUR } from '../gqls'

const Form = styled(AntForm)`
    max-width: 600px;
`

const rules = {
    required: {
        required: true,
        message: 'Обязательное поле'
    }
}

const phoneMask = IMask.createMask({
    mask: '70000000000'
})

const modules = {
    toolbar: [
        [{ size: ['small', false, 'large', 'huge'] }],
        ['bold', 'italic', 'underline', 'strike'],
        [{ 'list': 'ordered' }, { 'list': 'bullet' }],
        ['link'],
        ['clean'],
    ],
    clipboard: {
        matchVisual: false
    }
}

const HOST_NAME = process.env.REACT_APP_HOST_NAME
const DraggableUploadListItem = ({ originNode, file }) => {
    const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({
        id: file.uid,
    })
    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
        cursor: 'move',
    }
    return (
        <div
            ref={setNodeRef}
            style={style}
            className={isDragging ? 'is-dragging' : ''}
            {...attributes}
            {...listeners}
        >
            {file.status === 'error' && isDragging ? originNode.props.children : originNode}
        </div>
    )
}

const Tour = () => {
    const navigate = useNavigate()
    const [form] = Form.useForm()
    const { id } = useParams()

    const [images, setImages] = useState([])
    const [images_720, setImages720] = useState([])
    const [images_1024, setImages1024] = useState([])

    useQuery(FIND_UNIQUE_TOUR, {
        fetchPolicy: 'network-only',
        variables: {
            where: {
                id
            }
        },
        onCompleted: ({ findUniqueTour }) => {
            form.setFieldsValue({
                phone: findUniqueTour.phone,
                name: findUniqueTour.name,
                description: findUniqueTour.description,
                organization: findUniqueTour.organization,
                price: findUniqueTour.price,
                priceInfo: findUniqueTour.priceInfo,
                lasting: findUniqueTour.lasting,
                people: findUniqueTour.people,
                dates: findUniqueTour.dates,
                location: findUniqueTour.location,
                transportations: findUniqueTour.transportations,
                includes: findUniqueTour.includes,
                takes: findUniqueTour.takes,
                schedule: findUniqueTour.schedule,
                publish: findUniqueTour.publish,
            })
            const objectImages = findUniqueTour?.images?.map((object, index) => ({
                uid: index,
                name: object.replace(/\.[^/.]+$/, ""),
                status: 'done',
                url: HOST_NAME + `uploads/${object}`,
                response: {
                    filename: object
                }
            }))
            setImages(objectImages)
            const objectImages720 = findUniqueTour?.images_720?.map((object, index) => ({
                uid: index,
                name: object.replace(/\.[^/.]+$/, ""),
                status: 'done',
                url: HOST_NAME + `uploads/${object}`,
                response: {
                    filename: object
                }
            }))
            setImages720(objectImages720)
            const objectImages1024 = findUniqueTour?.images_1024?.map((object, index) => ({
                uid: index,
                name: object.replace(/\.[^/.]+$/, ""),
                status: 'done',
                url: HOST_NAME + `uploads/${object}`,
                response: {
                    filename: object
                }
            }))
            setImages1024(objectImages1024)
        }
    })

    const postImageCompress = async () => {
        await fetch(HOST_NAME + "compress-image/", {
            method: "POST",
            headers: {
                "Accept": "*/*",
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Method": "POST",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                photo_names: images.map((i) => i.response.filename),
            }),
        })
            .then((response) => response.json())
            .then((responseData) => {
                updateImageOneTourMutation({
                    variables: {
                        where: {
                            id
                        },
                        data: {
                            images_720: responseData['filenames_720'],
                            images_1024: responseData['filenames_1024']
                        }
                    }
                })
            })
    }

    const [updateOneTourMutation, { loading: updateOneTourLoading }] = useMutation(UPDATE_ONE_TOUR, {
        onCompleted: () => {
            message.success('Данные обновлены')
        },
        onError: e => {
            message.error('Что то пошло не так, повторите попытку позже')
        }
    })

    const [resetPhotoListOneTourMutation, { }] = useMutation(UPDATE_ONE_TOUR, {
        onCompleted: () => {
            message.success('Список картинок обновлена')
            postImageCompress()
        },
        onError: e => {
            message.error('Что то пошло не так, повторите попытку позже')
        }
    })

    const [updateImageOneTourMutation, { }] = useMutation(UPDATE_ONE_TOUR, {
        onCompleted: () => {
            message.success('Сжатие картинок прошла успешно')
            window.location.reload()
        },
        onError: e => {
            message.error('Что то пошло не так при сжатии картинок, повторите попытку позже')
        }
    })

    const [deleteOneTourMutation] = useMutation(DELETE_ONE_TOUR, {
        onCompleted: () => {
            message.success('Тур удален')
            navigate(-1)
        },
        onError: (err) => {
            console.error(err)
            message.error('Не удалось удалить')
        },
    })

    const sensor = useSensor(PointerSensor, {
        activationConstraint: {
            distance: 10,
        }
    })

    const handleSubmit = async (values) => {
        const files = images.map(object => object.response.filename) || []
        const files720 = images_720.map(object => object.response.filename) || []
        const files1024 = images_1024.map(object => object.response.filename) || []

        await updateOneTourMutation({
            variables: {
                where: {
                    id
                },
                data: {
                    phone: {
                        set: values.phone
                    },
                    name: {
                        set: values.name
                    },
                    description: {
                        set: values.description
                    },
                    organization: {
                        set: values.organization
                    },
                    images: files,
                    images_720: files720,
                    images_1024: files1024,
                    price: {
                        set: values.price
                    },
                    priceInfo: {
                        set: values.priceInfo
                    },
                    lasting: {
                        set: values.lasting
                    },
                    people: {
                        set: values.people
                    },
                    dates: values.dates,
                    location: {
                        set: values.location
                    },
                    schedule: {
                        set: values.schedule
                    },
                    publish: {
                        set: values.publish
                    },
                    transportations: values.transportations,
                    includes: values.includes,
                    takes: values.takes,
                }
            }
        })
    }

    const resetPhotoList = async () => {
        await resetPhotoListOneTourMutation({
            variables: {
                where: {
                    id
                },
                data: {
                    images_720: [],
                    images_1024: []
                }
            }
        })
    }

    const onChangePhone = (event) => {
        const maskedValue = phoneMask.resolve(event.target.value)
        form.setFieldValue('phone', maskedValue)
    }

    const onPreview = async (file) => {
        const name = file.response.filename
        window.open(HOST_NAME + `uploads/${name}`, '_blank')
    }

    const onDragEnd = ({ active, over }) => {
        if (active.id !== over?.id) {
            setImages((prev) => {
                const activeIndex = prev.findIndex((i) => i.uid === active.id)
                const overIndex = prev.findIndex((i) => i.uid === over?.id)
                return arrayMove(prev, activeIndex, overIndex)
            })
            if (images_720.length > 0)
                setImages720((prev) => {
                    const activeIndex = prev.findIndex((i) => i.uid === active.id)
                    const overIndex = prev.findIndex((i) => i.uid === over?.id)
                    return arrayMove(prev, activeIndex, overIndex == -1 ? 0 : overIndex)
                })
            if (images_1024.length > 0)
                setImages1024((prev) => {
                    const activeIndex = prev.findIndex((i) => i.uid === active.id)
                    const overIndex = prev.findIndex((i) => i.uid === over?.id)
                    return arrayMove(prev, activeIndex, overIndex == -1 ? 0 : overIndex)
                })
        }
    }

    const onDelete = async () => {
        await deleteOneTourMutation({
            variables: {
                where: {
                    id
                }
            }
        })
    }

    return (
        <>
            <Form form={form} onFinish={handleSubmit} layout='vertical'>
                <Form.Item label='Изображение' >
                    {images_720.length != images.length ?
                        <Button onClick={postImageCompress}>
                            Image compress
                        </Button> : ""
                    }
                    <Button onClick={resetPhotoList}>
                        Обнуление списка картинок
                    </Button>
                    <DndContext sensors={[sensor]} onDragEnd={onDragEnd}>
                        <SortableContext items={images.map((i) => i.uid)} strategy={verticalListSortingStrategy}>
                            <Upload
                                multiple
                                action={HOST_NAME + 'upload'}
                                listType='picture'
                                fileList={images}
                                onChange={({ fileList }) => {
                                    setImages(fileList)
                                    if (fileList.length > images_720.length) {
                                        var lastItem = fileList.slice(-1)[0];
                                        if (lastItem.status == 'done') {
                                            images_720.push(
                                                {
                                                    uid: images_720.length,
                                                    name: lastItem.response.filename_720.replace(/\.[^/.]+$/, ""),
                                                    status: 'done',
                                                    url: HOST_NAME + `uploads/${lastItem.response.filename_720}`,
                                                    response: {
                                                        filename: lastItem.response.filename_720
                                                    }
                                                }
                                            )
                                            setImages720(images_720)

                                            images_1024.push(
                                                {
                                                    uid: images_1024.length,
                                                    name: lastItem.response.filename_1024.replace(/\.[^/.]+$/, ""),
                                                    status: 'done',
                                                    url: HOST_NAME + `uploads/${lastItem.response.filename_1024}`,
                                                    response: {
                                                        filename: lastItem.response.filename_1024
                                                    }
                                                }
                                            )
                                            setImages1024(images_1024)
                                        }
                                    } else {
                                        var name_list = fileList.map(object => object.name)
                                        const localImageList = images_720
                                        localImageList.forEach(function (element, index) {
                                            const filename = element.name.replace("_720", "")
                                            if (name_list.indexOf(filename) == -1) {
                                                images_720.splice(index, 1);
                                                images_1024.splice(index, 1);
                                            }
                                        })
                                        setImages720(images_720)
                                        setImages1024(images_1024)
                                    }
                                }}
                                onPreview={onPreview}
                                itemRender={(originNode, file) => (
                                    <DraggableUploadListItem originNode={originNode} file={file} />
                                )}
                            >
                                <Button icon={<UploadOutlined />}>+ Загрузить</Button>
                            </Upload>
                        </SortableContext>
                    </DndContext>
                </Form.Item>
                <Form.Item rules={[rules.required]} name='name' label='Название тура'>
                    <Input placeholder='Введите название тура' />
                </Form.Item>
                <Form.Item name='description' label='Описание'>
                    <ReactQuill modules={modules} theme='snow' />
                </Form.Item>
                <Form.Item rules={[rules.required]} name='organization' label='Название организации'>
                    <Input placeholder='Введите название организации' />
                </Form.Item>
                <Form.Item rules={[rules.required]} name='phone' label='Номер телефона организатора'>
                    <Input onChange={onChangePhone} placeholder='Введите номер телефон' />
                </Form.Item>
                <Form.Item rules={[rules.required]} name='location' label='Название локации'>
                    <Input placeholder='Введите название локации' />
                </Form.Item>
                <Form.Item rules={[rules.required]} name='price' label='Цена'>
                    <InputNumber
                        min={0}
                        placeholder='Цена'
                        style={{ width: '100%' }}
                    />
                </Form.Item>
                <Form.Item rules={[rules.required]} name='priceInfo' label='Информация о цена'>
                    <Input placeholder='Введите информацию' />
                </Form.Item>
                <Form.Item name='people' label='Количество людей в группе'>
                    <Input placeholder='Введите количество людей' />
                </Form.Item>
                <Form.Item name='lasting' label='Продолжительность'>
                    <Input placeholder='Введите продолжительность тура' />
                </Form.Item>
                <Form.Item name='dates' label='Даты набора групп'>
                    <Select
                        mode='tags'
                        placeholder='Введите даты набора групп'
                    />
                </Form.Item>
                <Form.Item name='transportations' label='Вид транспорта'>
                    <Select
                        mode='tags'
                        placeholder='Введите вид транспорта'
                    />
                </Form.Item>
                <Form.Item name='includes' label='В стоимость входит'>
                    <Select
                        mode='tags'
                        placeholder='В стоимость входит'
                    />
                </Form.Item>
                <Form.Item name='takes' label='Что нужно с собой взять'>
                    <Select
                        mode='tags'
                        placeholder='Что нужно с собой взять'
                    />
                </Form.Item>
                <Form.Item name='schedule' label='Программа'>
                    <ReactQuill modules={modules} theme='snow' />
                </Form.Item>
                <Form.Item name='publish' label='Опубликовано'>
                    <Switch />
                </Form.Item>
                <Button loading={updateOneTourLoading} type='primary' htmlType='submit'>
                    Сохранить
                </Button>
                <Popconfirm title='Удалить?' onConfirm={onDelete}>
                    <Button type='primary' danger style={{ marginLeft: 5 }}>
                        Удалить
                    </Button>
                </Popconfirm>
            </Form>
        </>
    )
}

export default Tour

import React, { useState } from 'react'

import styled from 'styled-components'
import { Form as AntForm, Button, message, Input, Upload } from 'antd'
import { useMutation } from '@apollo/client'
import { useNavigate } from 'react-router-dom'

import { CREATE_ONE_POST } from '../gqls'

const Form = styled(AntForm)`
    max-width: 600px;
`

const { TextArea } = Input

const HOST_NAME = process.env.REACT_APP_HOST_NAME
const AddPost = () => {
    const navigate = useNavigate()
    const [form] = Form.useForm()
    const [images, setImages] = useState([])

    const [createOnePostMutation, { loading: createOnePostLoading }] = useMutation(CREATE_ONE_POST, {
        onCompleted: () => {
            form.resetFields()
            message.success('Пост добавлен')
            navigate(-1)
        },
        onError: e => {
            message.error('Что то пошло не так, повторите попытку позже')
        }
    })

    const handleSubmit = async (values) => {
        let postId

        if (values.postId) {
            const url = new URL(values.postId)
            postId = url.pathname.split('/')[2]
        }
        const files = images.map(object => object.response.filename) || ''

        await createOnePostMutation({
            variables: {
                data: {
                    postId,
                    user: values.user,
                    text: values.text,
                    date: (Date.now() / 1000).toString(),
                    image: files[0] || ''
                }
            }
        })
    }

    const onPreview = async (file) => {
        const name = file.response.filename
        window.open(`${HOST_NAME}uploads/${name}`, '_blank')
    }

    return (
        <>
            <Form form={form} onFinish={handleSubmit} layout='vertical'>
                <Form.Item name='postId' label='Ссылка поста instagram'>
                    <Input placeholder='Введите ссылку поста instagram' />
                </Form.Item>
                <Form.Item name='user' label='Автор'>
                    <Input placeholder='Введите автора' />
                </Form.Item>
                <Form.Item name='text' label='Описание'>
                    <TextArea placeholder='Введите описание' />
                </Form.Item>
                <Form.Item label='Изображение' >
                    <Upload
                        action={HOST_NAME + 'upload'}
                        listType='picture-card'
                        fileList={images}
                        onChange={({ fileList }) => {
                            setImages(fileList)
                        }}
                        onPreview={onPreview}
                    >
                        {
                            images.length === 0 ? '+ Загрузить' : null
                        }
                    </Upload>
                </Form.Item>
                <Button loading={createOnePostLoading} type='primary' htmlType='submit'>
                    Добавить
                </Button>
            </Form>
        </>
    )
}

export default AddPost

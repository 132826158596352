import React, { useState } from 'react'

import styled from 'styled-components'
import { Form as AntForm, Button, message, Input, Upload, InputNumber, Select, Switch } from 'antd'
import { UploadOutlined } from '@ant-design/icons'
import { useMutation } from '@apollo/client'
import { useNavigate } from 'react-router-dom'
import IMask from 'imask'
import ReactQuill from 'react-quill'
import { DndContext, PointerSensor, useSensor } from '@dnd-kit/core'
import { arrayMove, SortableContext, useSortable, verticalListSortingStrategy } from '@dnd-kit/sortable'

import { CSS } from '@dnd-kit/utilities'

import { CREATE_ONE_TOUR } from '../gqls'

const Form = styled(AntForm)`
    max-width: 600px;
`

const rules = {
    required: {
        required: true,
        message: 'Обязательное поле'
    }
}

const phoneMask = IMask.createMask({
    mask: '70000000000'
})

const modules = {
    toolbar: [
        [{ 'header': [1, 2, 3, false] }],
        ['bold', 'italic', 'underline', 'strike'],
        [{ 'list': 'ordered' }, { 'list': 'bullet' }],
        ['link'],
        ['clean'],
    ],
    clipboard: {
        matchVisual: false
    }
}

const HOST_NAME = process.env.REACT_APP_HOST_NAME
const DraggableUploadListItem = ({ originNode, file }) => {
    const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({
        id: file.uid,
    })
    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
        cursor: 'move',
    }
    return (
        <div
            ref={setNodeRef}
            style={style}
            className={isDragging ? 'is-dragging' : ''}
            {...attributes}
            {...listeners}
        >
            {file.status === 'error' && isDragging ? originNode.props.children : originNode}
        </div>
    )
}

const AddTour = () => {
    const navigate = useNavigate()
    const [form] = Form.useForm()

    const [images, setImages] = useState([])

    const [createOneTourMutation, { loading: createOneTourLoading }] = useMutation(CREATE_ONE_TOUR, {
        onCompleted: () => {
            form.resetFields()
            message.success('Компания добавлена')
            navigate(-1)
        },
        onError: e => {
            message.error('Что то пошло не так, повторите попытку позже')
        }
    })

    const sensor = useSensor(PointerSensor, {
        activationConstraint: {
            distance: 10,
        }
    })

    const handleSubmit = async (values) => {
        const files = images.map(object => object.response.filename) || []

        await createOneTourMutation({
            variables: {
                data: {
                    phone: values.phone,
                    name: values.name,
                    description: values.description,
                    organization: values.organization,
                    images: files,
                    price: values.price,
                    priceInfo: values.priceInfo,
                    lasting: values.lasting,
                    people: values.people,
                    dates: values.dates,
                    location: values.location,
                    transportations: values.transportations,
                    includes: values.includes,
                    takes: values.takes,
                    publish: values.publish,
                }
            }
        })
    }

    const onChangePhone = (event) => {
        const maskedValue = phoneMask.resolve(event.target.value)
        form.setFieldValue('phone', maskedValue)
    }

    const onPreview = async (file) => {
        const name = file.response.filename
        window.open(HOST_NAME + `uploads/${name}`, '_blank')
    }

    const onDragEnd = ({ active, over }) => {
        if (active.id !== over?.id) {
            setImages((prev) => {
                const activeIndex = prev.findIndex((i) => i.uid === active.id)
                const overIndex = prev.findIndex((i) => i.uid === over?.id)
                return arrayMove(prev, activeIndex, overIndex)
            })
        }
    }

    return (
        <>
            <Form form={form} onFinish={handleSubmit} layout='vertical'>
                <Form.Item label='Изображение' >
                    <DndContext sensors={[sensor]} onDragEnd={onDragEnd}>
                        <SortableContext items={images.map((i) => i.uid)} strategy={verticalListSortingStrategy}>
                            <Upload
                                multiple
                                action={HOST_NAME + 'upload'}
                                listType='picture'
                                fileList={images}
                                onChange={({ fileList }) => {
                                    setImages(fileList)
                                }}
                                onPreview={onPreview}
                                itemRender={(originNode, file) => (
                                    <DraggableUploadListItem originNode={originNode} file={file} />
                                )}
                            >
                                <Button icon={<UploadOutlined />}>+ Загрузить</Button>
                            </Upload>
                        </SortableContext>
                    </DndContext>
                </Form.Item>
                <Form.Item rules={[rules.required]} name='name' label='Название тура'>
                    <Input placeholder='Введите название тура' />
                </Form.Item>
                <Form.Item name='description' label='Описание'>
                    <ReactQuill modules={modules} theme='snow' />
                </Form.Item>
                <Form.Item rules={[rules.required]} name='organization' label='Название организации'>
                    <Input placeholder='Введите название организации' />
                </Form.Item>
                <Form.Item rules={[rules.required]} name='phone' label='Номер телефона организатора'>
                    <Input onChange={onChangePhone} placeholder='Введите номер телефон' />
                </Form.Item>
                <Form.Item rules={[rules.required]} name='location' label='Название локации'>
                    <Input placeholder='Введите название локации' />
                </Form.Item>
                <Form.Item rules={[rules.required]} name='price' label='Цена тура'>
                    <InputNumber
                        min={0}
                        placeholder='Цена'
                        style={{ width: '100%' }}
                    />
                </Form.Item>
                <Form.Item rules={[rules.required]} name='priceInfo' label='Информация о цена'>
                    <Input placeholder='Введите информацию' />
                </Form.Item>
                <Form.Item name='people' label='Количество людей в группе'>
                    <Input placeholder='Введите количество людей' />
                </Form.Item>
                <Form.Item name='lasting' label='Продолжительность'>
                    <InputNumber
                        min={0}
                        placeholder='Введите количество ночей'
                        style={{ width: '100%' }}
                    />
                </Form.Item>
                <Form.Item name='dates' label='Даты набора групп'>
                    <Select
                        mode='tags'
                        placeholder='Введите даты набора групп'
                    />
                </Form.Item>
                <Form.Item name='transportations' label='Вид транспорта'>
                    <Select
                        mode='tags'
                        placeholder='Введите вид транспорта'
                    />
                </Form.Item>
                <Form.Item name='includes' label='В стоимость входит'>
                    <Select
                        mode='tags'
                        placeholder='В стоимость входит'
                    />
                </Form.Item>
                <Form.Item name='takes' label='Что нужно с собой взять'>
                    <Select
                        mode='tags'
                        placeholder='Что нужно с собой взять'
                    />
                </Form.Item>
                <Form.Item name='schedule' label='Программа'>
                    <ReactQuill modules={modules} theme='snow' />
                </Form.Item>
                <Form.Item name='publish' label='Опубликовано'>
                    <Switch defaultValue={true} />
                </Form.Item>
                <Button loading={createOneTourLoading} type='primary' htmlType='submit'>
                    Добавить
                </Button>
            </Form>
        </>
    )
}

export default AddTour

import React from 'react'
import styled from 'styled-components'

const Wrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: 36px;
    margin-bottom: 12px;

    .left {
        .title {
            font-size: 18px;
        }
        .help-text {
            color: gray;
            font-size: 14px;
            margin-top: 2px;
        }
    }

    .right {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
    }
`

const Title = ({ text, helpText, action, style }) => {
    return (
        <Wrapper hasAction={action ? true : false} style={style}>
            <div className='left'>
                <div className='title'>{text}</div>
                {helpText && <div className='help-text'>{helpText}</div>}
            </div>
            <div className='right'>
                {action && action}
            </div>
        </Wrapper>
    )
}

export default Title
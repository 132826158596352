import React, { useState } from 'react'

import styled from 'styled-components'
import { Form as AntForm, Button, message, Popconfirm, Upload, Select, Input } from 'antd'
import { useMutation, useQuery } from '@apollo/client'
import { useParams, useNavigate } from 'react-router-dom'

import { UPDATE_ONE_ICON, FIND_UNIQUE_ICON, DELETE_ONE_ICON, FIND_MANY_OBJECT } from '../gqls'

const Form = styled(AntForm)`
    max-width: 600px;
`

const rules = {
    required: {
        required: true,
        message: 'Обязательное поле'
    }
}

const includeIcons = [
    'breakfast',
    'pet',
    'bathhouse',
    'garage',
    'transfer',
    'playground',
    'pool',
    'conditioner',
    'wifi',
    'bathroom',
    'kitchen',
    'fridge',
    'dishes',
    'river',
]

const HOST_NAME = process.env.REACT_APP_HOST_NAME
const Icon = () => {
    const navigate = useNavigate()
    const [form] = Form.useForm()
    const { id } = useParams()
    const [images, setImages] = useState([])
    const [comforts, setComforts] = useState([])

    useQuery(FIND_UNIQUE_ICON, {
        fetchPolicy: 'network-only',
        variables: {
            where: {
                id
            }
        },
        onCompleted: ({ findUniqueIcon }) => {
            form.setFieldsValue({
                name: findUniqueIcon.name,
            })
            setImages([{
                uid: findUniqueIcon.id,
                name: findUniqueIcon.image,
                status: 'done',
                url:  HOST_NAME + `uploads/${findUniqueIcon.image}`,
                response: {
                    filename: findUniqueIcon.image
                }
            }])
        }
    })

    useQuery(FIND_MANY_OBJECT, {
        fetchPolicy: 'network-only',
        onCompleted: ({ findManyObject }) => {
            const comfortsObject = findManyObject?.map(object => object.comforts.filter(comfort => !includeIcons?.includes(comfort)))?.flat(Infinity)
            const comfortsData = comfortsObject?.map(object => ({
                value: object,
                label: object
            }))
            setComforts(comfortsData)
        }
    })

    const [updateOneIconMutation, { loading: updateOneIconLoading }] = useMutation(UPDATE_ONE_ICON, {
        onCompleted: () => {
            message.success('Данные обновлены')
        },
        onError: e => {
            message.error('Что то пошло не так, повторите попытку позже')
        }
    })

    const [deleteOneIconMutation] = useMutation(DELETE_ONE_ICON, {
        onCompleted: () => {
            message.success('Пост удален')
            navigate(-1)
        },
        onError: (err) => {
            console.error(err)
            message.error('Не удалось удалить')
        },
    })

    const handleSubmit = async (values) => {
        const files = images.map(object => object.response.filename) || ''

        await updateOneIconMutation({
            variables: {
                where: {
                    id
                },
                data: {
                    name: {
                        set: values.name
                    },
                    category: {
                        set: values.category
                    },
                    image: {
                        set: files[0] || ''
                    }
                }
            }
        })
    }

    const onDelete = async () => {
        if (id) {
            await deleteOneIconMutation({
                variables: {
                    where: {
                        id
                    }
                }
            })
        }
    }

    const onPreview = async (file) => {
        const name = file.response.filename
        window.open(HOST_NAME + `uploads/${name}`, '_blank')
    }

    return (
        <>
            <Form form={form} onFinish={handleSubmit} layout='vertical'>
                <Form.Item name='name' label='Название' rules={[rules.required]}>
                    <Select
                        placeholder='Выберите из списка'
                        options={comforts}
                    />
                </Form.Item>
                <Form.Item name='category' label='Категория' rules={[rules.required]}>
                    <Input placeholder='Введите категорию' />
                </Form.Item>
                <Form.Item name='image' label='Изображение' rules={[rules.required]}>
                    <Upload
                        action={HOST_NAME + 'upload'}
                        listType='picture-card'
                        fileList={images}
                        onChange={({ fileList }) => {
                            setImages(fileList)
                        }}
                        onPreview={onPreview}
                    >
                        {
                            images.length === 0 ? '+ Загрузить' : null
                        }
                    </Upload>
                </Form.Item>
                <Button loading={updateOneIconLoading} type='primary' htmlType='submit'>
                    Сохранить
                </Button>
                <Popconfirm title='Удалить?' onConfirm={onDelete}>
                    <Button type='primary' danger style={{ marginLeft: 5 }}>
                        Удалить
                    </Button>
                </Popconfirm>
            </Form>
        </>
    )
}

export default Icon

import React from 'react'

import styled from 'styled-components'
import { Form as AntForm, Button, Select, message, Input } from 'antd'
import { useMutation } from '@apollo/client'
import { useNavigate } from 'react-router-dom'
import IMask from 'imask'

import { CREATE_ONE_COMPANY } from '../gqls'

const Form = styled(AntForm)`
    max-width: 600px;
`

const rules = {
    required: {
        required: true,
        message: 'Обязательное поле'
    }
}

const phoneMask = IMask.createMask({
    mask: '70000000000'
})

const AddCompany = () => {
    const navigate = useNavigate()
    const [form] = Form.useForm()
    const paymentTypes = [
        {
            label: 'Оплата по номеру телефона',
            value: 0
        },
        {
            label: 'Оплата по карте',
            value: 1
        }
    ]

    const [createOneCompanyMutation, { loading: createOneCompanyLoading }] = useMutation(CREATE_ONE_COMPANY, {
        onCompleted: () => {
            form.resetFields()
            message.success('Компания добавлена')
            navigate(-1)
        },
        onError: e => {
            message.error('Что то пошло не так, повторите попытку позже')
        }
    })

    const handleSubmit = async (values) => {
        await createOneCompanyMutation({
            variables: {
                data: {
                    name: values.name,
                    phone: values.phone,
                    contact: values.contact,
                    payment: values.payment,
                    paymentCardNumber: values.paymentCardNumber,
                    paymentType: values.paymentType,
                    email: values.email,
                }
            }
        })
    }

    const onChangePhone = (event) => {
        const maskedValue = phoneMask.resolve(event.target.value)
        form.setFieldValue('phone', maskedValue)
    }

    const onChangePhoneContact = (event) => {
        const maskedValue = phoneMask.resolve(event.target.value)
        form.setFieldValue('contact', maskedValue)
    }

    const onChangePhonePayment = (event) => {
        const maskedValue = phoneMask.resolve(event.target.value)
        form.setFieldValue('payment', maskedValue)
    }

    return (
        <>
            <Form form={form} onFinish={handleSubmit} layout='vertical'>
                <Form.Item rules={[rules.required]} name='name' label='Название компании'>
                    <Input placeholder='Введите название' />
                </Form.Item>
                <Form.Item rules={[rules.required]} name='phone' label='Номер телефона'>
                    <Input onChange={onChangePhone} placeholder='Введите номер телефон' />
                </Form.Item>
                <Form.Item rules={[rules.required]} name='contact' label='Контактный номер телефона'>
                    <Input onChange={onChangePhoneContact} placeholder='Введите номер телефон' />
                </Form.Item>
                <Form.Item name='payment' label='Номер телефона для оплаты'>
                    <Input onChange={onChangePhonePayment} placeholder='Введите номер телефона' />
                </Form.Item>
                <Form.Item name='paymentCardNumber' label='Номер карты для оплаты'>
                    <Input placeholder='Введите номер карты' />
                </Form.Item>
                <Form.Item name='paymentType' label='Тип оплаты'>
                    <Select
                        placeholder='Выберите тип оплаты'
                        options={paymentTypes}
                    />
                </Form.Item>
                <Form.Item rules={[rules.required]} name='email' label='Email компании'>
                    <Input placeholder='Введите email' />
                </Form.Item>
                <Button loading={createOneCompanyLoading} type='primary' htmlType='submit'>
                    Добавить
                </Button>
            </Form>
        </>
    )
}

export default AddCompany

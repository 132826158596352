import { useState } from 'react'
import styled from 'styled-components'
import { Card as AntCard, Input, Button as AntButton, message } from 'antd'
import { useMutation } from '@apollo/client'

import { SIGN_IN_ADMIN } from '../gqls'

const Card = styled(AntCard)`
    width: 400px;

    @media only screen and (max-width: 420px) {
        width: 95%;
    }
`

const Button = styled(AntButton)``

const Wrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 100vh;

    @media only screen and (max-width: 420px) {
        justify-content: flex-start;
        padding-top: 30px;
    }
`

const Auth = () => {
    const [login, setLogin] = useState('')
    const [password, setPassword] = useState('')

    const [signInAdminMutation] = useMutation(SIGN_IN_ADMIN, {
        onCompleted: async ({ signInAdmin }) => {
            const { token } = signInAdmin
            await localStorage.setItem('token', token)
            window.location.replace('/')
        },
        onError: () => {
            message.error('Не правильный логин или пароль')
        }
    })

    const onSignIn = async () => {
        if (!login) {
            message.error('Введите логин')
            return
        }
        if (!password) {
            message.error('Введите пароль')
            return
        }
        await signInAdminMutation({
            variables: {
                data: {
                    login,
                    password
                }
            }
        })
    }

    return (
        <Wrapper>
            <Card title='Вход в панель администратора'>
                <Input value={login} onChange={event => setLogin(event.target.value)} placeholder='Введите логин' />
                <Input.Password value={password} onChange={event => setPassword(event.target.value)} placeholder='Введите пароль' style={{ marginTop: 12 }} />
                <Button style={{ marginTop: 24 }} type='primary' onClick={onSignIn}>
                    Войти
                </Button>
            </Card>
        </Wrapper>
    )
}

export default Auth
import React from 'react'

import styled from 'styled-components'
import { Form as AntForm, Typography, Popconfirm, Button, message } from 'antd'
import { useQuery, useMutation } from '@apollo/client'
import { useParams, useNavigate } from 'react-router-dom'
import dayjs from 'dayjs'
import 'dayjs/locale/ru'

import { FIND_UNIQUE_REQUEST, UPDATE_ONE_REQUEST } from '../gqls'

const Form = styled(AntForm)`
    max-width: 600px;
`

const { Text } = Typography

const Review = () => {
    const navigate = useNavigate()
    const { id } = useParams()

    const { data: findUniqueRequestData, loading: findUniqueRequestLoading, error: findUniqueRequestError } = useQuery(FIND_UNIQUE_REQUEST, {
        variables: {
            where: {
                id
            }
        },
    })

    const [updateOneRequestMutation] = useMutation(UPDATE_ONE_REQUEST, {
        onCompleted: () => {
            message.success('Отзыв удален')
            navigate(-1)
        },
        onError: (err) => {
            console.error(err)
            message.error('Не удалось удалить')
        },
    })

    if (findUniqueRequestLoading || findUniqueRequestError) {
        return null
    }

    function dayTitle(number) {
        if (number < 0) return ''
        if (number > 10 && [11, 12, 13, 14].includes(number % 100)) return number + ' дней';
        var last_num = number % 10;
        if (last_num == 1) return number + ' день';
        if ([2, 3, 4].includes(last_num)) return number + ' дня';
        if ([5, 6, 7, 8, 9, 0].includes(last_num)) return number + ' дней';
    }

    const { findUniqueRequest } = findUniqueRequestData

    const onDelete = async () => {
        await updateOneRequestMutation({
            variables: {
                where: {
                    id
                },
                data: {
                    review: {
                        set: null
                    },
                    rating: {
                        set: 0
                    }
                }
            }
        })
    }


    return (
        <>
            <Form layout='vertical'>
                <Form.Item label='Дата и время брони'>
                    <Text>{dayjs(findUniqueRequest.reservedAt).format('DD.MM.YYYY')} {findUniqueRequest.schedule.startAt}-{findUniqueRequest.schedule.endAt}</Text>
                </Form.Item>
                <Form.Item label='Имя клиента'>
                    <Text>{findUniqueRequest.name}</Text>
                </Form.Item>
                <Form.Item label='Телефон клиента'>
                    <Text>{findUniqueRequest.phone}</Text>
                </Form.Item>
                <Form.Item label='Обьект'>
                    <Text>{findUniqueRequest?.object?.name || '-'}</Text>
                </Form.Item>
                <Form.Item label='Оценка'>
                    <Text>{findUniqueRequest.rating}</Text>
                </Form.Item>
                <Form.Item label='Отзыв'>
                    <Text>{findUniqueRequest.review || '-'}</Text>
                </Form.Item>
                <Form.Item label='Отзыв'>
                    <Text>{findUniqueRequest.ratingAt ? findUniqueRequest.ratingAt + ' ' + dayTitle(3 - (dayjs(findUniqueRequest.ratingAt).diff(dayjs(), 'day') * -1)) : '-'}</Text>
                </Form.Item>
                <Popconfirm title='Удалить?' onConfirm={onDelete}>
                    <Button type='primary' danger>
                        Удалить
                    </Button>
                </Popconfirm>
            </Form>
        </>
    )
}

export default Review

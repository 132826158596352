import React, { useState } from 'react'

import styled from 'styled-components'
import { Form as AntForm, Typography, Button, Select, Switch, message } from 'antd'
import { useQuery, useMutation } from '@apollo/client'
import { useParams, useNavigate } from 'react-router-dom'
import dayjs from 'dayjs'
import 'dayjs/locale/ru'
import tz from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

import { FIND_UNIQUE_REQUEST, UPDATE_ONE_REQUEST } from '../gqls'

const Form = styled(AntForm)`
    max-width: 600px;
`

const { Text } = Typography

const statuses = [
    {
        label: 'Ждет подтверждения',
        value: '0'
    },
    {
        label: 'Оплачен',
        value: '1'
    },
    {
        label: 'Отменен',
        value: '3'
    },
    {
        label: 'Отменен гостем',
        value: '4'
    }
]

const Request = () => {
    const navigate = useNavigate()
    const [form] = Form.useForm()
    const { id } = useParams()
    const [_statusChangeHistory, _setStatusChangeHistory] = useState([])
    const [textStatusChangeHistory, setTextStatusChangeHistory] = useState('')

    const { data: findUniqueRequestData, loading: findUniqueRequestLoading, error: findUniqueRequestError } = useQuery(FIND_UNIQUE_REQUEST, {
        variables: {
            where: {
                id
            }
        },
        onCompleted: ({ findUniqueRequest }) => {
            form.setFieldsValue({
                status: findUniqueRequest.status,
                pushingNotification: findUniqueRequest.pushingNotification,
            })
            _setStatusChangeHistory(findUniqueRequest.statusChangeHistory ? findUniqueRequest.statusChangeHistory : [])
            statusToText(findUniqueRequest.statusChangeHistory ? findUniqueRequest.statusChangeHistory : [])
        }
    })

    const [updateOneRequestMutation, { loading: updateOneRequestLoading }] = useMutation(UPDATE_ONE_REQUEST, {
        onCompleted: () => {
            message.success('Данные обновлены')
        },
        onError: e => {
            message.error('Что то пошло не так, повторите попытку позже')
        }
    })

    function statusToText(list) {
        const _statuses = {
            '-1': 'Изменена дата брони',
            '0': 'Ждет подтверждения',
            '1': 'Оплачен',
            '2': 'Завершен',
            '3': 'Отменен',
            '4': 'Отменен гостем',
        }
        var text = ''
        list.forEach(element => {
            const item = JSON.parse(element)
            text = text + `Статус: ${_statuses[item["status"]]}, дата: ${item["date"]}, кто ${item["who"]}\n`
        });
        setTextStatusChangeHistory(text)
    }


    if (findUniqueRequestLoading || findUniqueRequestError) {
        return null
    }

    const { findUniqueRequest } = findUniqueRequestData

    const handleSubmit = async (values) => {
        var tempData = [];
        _statusChangeHistory.forEach(element => {
            tempData.push(element)
        });
        dayjs.extend(utc);
        dayjs.extend(tz);
        tempData.push(JSON.stringify({
            'date': dayjs().tz("Asia/Yakutsk").format('YYYY-MM-DD HH:mm:ss'),
            'status': values.status,
            'who': 'admin_panel'
        }))
        _setStatusChangeHistory(tempData)
        await updateOneRequestMutation({
            variables: {
                where: {
                    id
                },
                data: {
                    status: {
                        set: values.status
                    },
                    statusChangeHistory: tempData,
                    pushingNotification: {
                        set: values.pushingNotification
                    }
                }
            }
        })
    }

    return (
        <>
            <Form form={form} onFinish={handleSubmit} layout='vertical'>
                <Form.Item label='Дата оформление'>
                    <Text>{dayjs(findUniqueRequest.createdAt).format('DD.MM.YYYY')}</Text>
                </Form.Item>
                <Form.Item label='Дата и время брони'>
                    <Text>{dayjs(findUniqueRequest.reservedAt).format('DD.MM.YYYY')} {findUniqueRequest.schedule != null ? findUniqueRequest.schedule.startAt + '-' + findUniqueRequest.schedule.endAt : ''}</Text>
                </Form.Item>
                <Form.Item label='Имя'>
                    <Text>{findUniqueRequest.name}</Text>
                </Form.Item>
                <Form.Item label='Телефон'>
                    <Text>{findUniqueRequest.phone}</Text>
                </Form.Item>
                <Form.Item label='Обьект'>
                    <Text>{findUniqueRequest?.object?.name}</Text>
                </Form.Item>
                <Form.Item label='Цена'>
                    <Text>{findUniqueRequest.price != null ? (findUniqueRequest.discount || findUniqueRequest.price) : (findUniqueRequest.schedule != null ? (findUniqueRequest.schedule.discount || findUniqueRequest.price) : '-')} ₽</Text>
                </Form.Item>
                <Form.Item label='Комментарий'>
                    <Text>{findUniqueRequest.comment || '-'}</Text>
                </Form.Item>
                <Form.Item label='Количество гостей'>
                    <Text>{findUniqueRequest.people || '-'}</Text>
                </Form.Item>
                <Form.Item label='История изменения статуса'>
                    <Text style={{ whiteSpace: "pre-line" }}>{textStatusChangeHistory}</Text>
                </Form.Item>
                <Form.Item label='Комментарий при отмене'>
                    <Text>{findUniqueRequest.cmntText || '-'}</Text>
                </Form.Item>
                <Form.Item name='status' label='Статус'>
                    <Select placeholder='Статус' options={statuses} />
                </Form.Item>
                <Form.Item name='pushingNotification' label='Отправить уведомление'>
                    <Switch />
                </Form.Item>
                <Button loading={updateOneRequestLoading} type='primary' htmlType='submit'>
                    Сохранить
                </Button>
            </Form>
        </>
    )
}

export default Request

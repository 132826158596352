import { useState, useMemo } from 'react'
import {
    Table,
    Button,
    Form,
    Input,
} from 'antd'
import { useQuery } from '@apollo/client'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import Title from '../components/Title'

import { FIND_MANY_PROMOTION, FIND_MANY_PROMOTION_COUNT } from '../gqls'

const Filters = styled(Form)`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 20px;

    .item {
        width: 200px;
        margin-right: 15px;
    }
`

const limit = 10

const Promotions = () => {
    const [currentPage, setCurrentPage] = useState(1)
    const [search, setSearch] = useState('')

    const variables = useMemo(() => ({
        where: {
            OR: [
                {
                    name: { contains: search, mode: 'insensitive' },
                },
            ]
        }
    }), [search])

    const { data: findManyPromotionData, loading: findManyPromotionLoading } = useQuery(FIND_MANY_PROMOTION, {
        variables: {
            ...variables,
            take: limit * currentPage,
            skip: 0,
            orderBy: {
                weight: 'asc'
            },
        },
        fetchPolicy: 'network-only'
    })

    const { data: findManyPromotionCountData, loading: findManyPromotionCountLoading } = useQuery(FIND_MANY_PROMOTION_COUNT, {
        variables,
        fetchPolicy: 'network-only'
    })

    const findManyPromotion = findManyPromotionData?.findManyPromotion || []
    const findManyPromotionCount = findManyPromotionCountData?.findManyPromotionCount || 0

    const handleChangeTable = ({ current }) => setCurrentPage(current)

    return (
        <>
            <Title
                text={`Список подборок (${findManyPromotionCount})`}
                action={
                    <Link to={'add'}>
                        <Button type='primary'>Добавить</Button>
                    </Link>
                }
            />
            <Filters>
                <Input
                    placeholder='Поиск'
                    allowClear
                    onChange={e => setSearch(e.target.value ? e.target.value : '')}
                    className='item'
                />
            </Filters>
            <Table
                loading={findManyPromotionLoading || findManyPromotionCountLoading}
                rowKey={(obj) => obj.id}
                dataSource={findManyPromotion}
                scroll={{ x: 600 }}
                size={window.innerWidth < 500 ? 'small' : 'large'}
                pagination={{
                    current: currentPage,
                    total: findManyPromotionCount,
                    pageSize: 10
                }}
                onChange={handleChangeTable}
                columns={[
                    {
                        title: 'id',
                        dataIndex: 'id',
                        key: 'id',
                        render: (id) => <Link to={id}>{id}</Link>
                    },
                    {
                        title: 'Название',
                        dataIndex: 'name',
                        key: 'name',
                        render: (name) => <span>{name}</span>
                    },
                    {
                        title: 'Вес',
                        dataIndex: 'weight',
                        key: 'weight',
                        render: (weight) => <span>{weight}</span>
                    },
                    {
                        title: 'Статус',
                        dataIndex: 'status',
                        key: 'status',
                        render: (status) => <span>{status ? 'Видно' : 'Скрыто'}</span>
                    },
                ]}

            />
        </>
    )
}

export default Promotions
import React, { useState } from 'react'

import styled from 'styled-components'
import { Form as AntForm, Button, message, Upload, Select, Input } from 'antd'
import { useMutation, useQuery } from '@apollo/client'
import { useNavigate } from 'react-router-dom'

import { CREATE_ONE_ICON, FIND_MANY_OBJECT } from '../gqls'

const Form = styled(AntForm)`
    max-width: 600px;
`

const rules = {
    required: {
        required: true,
        message: 'Обязательное поле'
    }
}

const includeIcons = [
    'breakfast',
    'pet',
    'bathhouse',
    'garage',
    'transfer',
    'playground',
    'pool',
    'conditioner',
    'wifi',
    'bathroom',
    'kitchen',
    'fridge',
    'dishes',
    'river',
]
const HOST_NAME = process.env.REACT_APP_HOST_NAME

const AddIcon = () => {
    const navigate = useNavigate()
    const [form] = Form.useForm()
    const [images, setImages] = useState([])
    const [comforts, setComforts] = useState([])

    const [createOneIconMutation, { loading: createOneIconLoading }] = useMutation(CREATE_ONE_ICON, {
        onCompleted: () => {
            form.resetFields()
            message.success('Пост добавлен')
            navigate(-1)
        },
        onError: e => {
            message.error('Что то пошло не так, повторите попытку позже')
        }
    })

    useQuery(FIND_MANY_OBJECT, {
        fetchPolicy: 'network-only',
        onCompleted: ({ findManyObject }) => {
            const comfortsObject = findManyObject?.map(object => object.comforts.filter(comfort => !includeIcons?.includes(comfort)))?.flat(Infinity)
            const comfortsData = comfortsObject?.map((object, index) => ({
                value: object,
                label: object,
                key: index
            }))
            const sortedComfortsData=comfortsData.sort((a,b)=>a.label.localeCompare(b.label))
            setComforts(sortedComfortsData)
        }
    })
    
    const handleSubmit = async (values) => {
        const files = images.map(object => object.response.filename) || ''
        await createOneIconMutation({
            variables: {
                data: {
                    name: values.name,
                    category: values.category,
                    image: files[0] || ''
                }
            }
        })
    }

    const onPreview = async (file) => {
        const name = file.response.filename
        window.open(`${HOST_NAME}uploads/${name}`, '_blank')
    }
    
    return (
        <>
            <Form form={form} onFinish={handleSubmit} layout='vertical'>
                <Form.Item name='name' label='Название' rules={[rules.required]}>
                     <Select
                        showSearch
                        placeholder='Выберите из списка'
                        options={comforts}
                        filterOption={(input, option)=>
                              option.label.toLowerCase().includes(input.toLowerCase())
                          }
                    /> 
                </Form.Item>
                <Form.Item name='category' label='Категория' rules={[rules.required]}>
                    <Input placeholder='Введите категорию' />
                </Form.Item>
                <Form.Item name='image' label='Изображение' rules={[rules.required]}>
                    <Upload
                        action={HOST_NAME + 'upload'}
                        listType='picture-card'
                        fileList={images}
                        onChange={({ fileList }) => {
                            setImages(fileList)
                        }}
                        onPreview={onPreview}
                    >
                        {
                            images.length === 0 ? '+ Загрузить' : null
                        }
                    </Upload>
                </Form.Item>
                <Button loading={createOneIconLoading} type='primary' htmlType='submit'>
                    Добавить
                </Button>
            </Form>
        </>
    )
}

export default AddIcon

import React, { useState } from 'react'

import styled from 'styled-components'
import { Form as AntForm, Button, message, Input, Popconfirm, InputNumber, Select, Switch, DatePicker } from 'antd'
import { useMutation, useQuery } from '@apollo/client'
import { useParams, useNavigate } from 'react-router-dom'
import IMask from 'imask'
import dayjs from 'dayjs'
import 'dayjs/locale/ru'

import { UPDATE_ONE_SCHEDULE, FIND_UNIQUE_SCHEDULE, DELETE_ONE_SCHEDULE } from '../gqls'

const Form = styled(AntForm)`
    max-width: 600px;
`

const timeMask = IMask.createMask({
    overwrite: true,
    autofix: true,
    mask: 'HH:MM',
    blocks: {
        HH: {
            mask: IMask.MaskedRange,
            placeholderChar: 'HH',
            from: 0,
            to: 23,
            maxLength: 2
        },
        MM: {
            mask: IMask.MaskedRange,
            placeholderChar: 'MM',
            from: 0,
            to: 59,
            maxLength: 2
        }
    }
})


const week = [
    {
        value: 'Понедельник',
        label: 'Понедельник'
    },
    {
        value: 'Вторник',
        label: 'Вторник'
    },
    {
        value: 'Среда',
        label: 'Среда'
    },
    {
        value: 'Четверг',
        label: 'Четверг'
    },
    {
        value: 'Пятница',
        label: 'Пятница'
    },
    {
        value: 'Суббота',
        label: 'Суббота'
    },
    {
        value: 'Воскресенье',
        label: 'Воскресенье'
    }
]

const rules = {
    required: {
        required: true,
        message: 'Обязательное поле'
    }
}

const format = 'DD.MM.YYYY'

const Schedule = () => {
    const navigate = useNavigate()
    const [form] = Form.useForm()
    const { id } = useParams()

    const [days, setDays] = useState([])
    const [closes, setCloses] = useState([])

    useQuery(FIND_UNIQUE_SCHEDULE, {
        variables: {
            where: {
                id
            }
        },
        onCompleted: ({ findUniqueSchedule }) => {
            form.setFieldsValue({
                name: findUniqueSchedule.name,
                price: findUniqueSchedule.price,
                reserve: findUniqueSchedule.reserve,
                discount: findUniqueSchedule.discount,
                cancel: findUniqueSchedule.cancel,
                week: findUniqueSchedule.week,
                startAt: findUniqueSchedule.startAt,
                endAt: findUniqueSchedule.endAt,
                holiday: findUniqueSchedule.holiday,
                fullday: findUniqueSchedule.fullday,
                weightSort: findUniqueSchedule.weightSort,
                visible: findUniqueSchedule.visible
            })
            if (findUniqueSchedule?.days?.length > 0) {
                setDays(findUniqueSchedule.days.map(object => dayjs(object, format)))
            }
            if (findUniqueSchedule?.closes?.length > 0) {
                setCloses(findUniqueSchedule.closes.map(object => dayjs(object, format)))
            }
        }
    })

    const [updateOneScheduleMutation, { loading: updateOneScheduleLoading }] = useMutation(UPDATE_ONE_SCHEDULE, {
        onCompleted: () => {
            message.success('Данные обновлены')
        },
        onError: e => {
            message.error('Что то пошло не так, повторите попытку позже')
        }
    })

    const [deleteOneScheduleMutation] = useMutation(UPDATE_ONE_SCHEDULE, {
        onCompleted: () => {
            message.success('Пост удален')
            navigate(-1)
        },
        onError: (err) => {
            console.error(err)
            message.error('Не удалось удалить')
        },
    })

    const handleSubmit = async (values) => {
        await updateOneScheduleMutation({
            variables: {
                where: {
                    id
                },
                data: {
                    name: {
                        set: values.name
                    },
                    price: {
                        set: values.price
                    },
                    reserve: {
                        set: values.reserve
                    },
                    discount: {
                        set: values.discount
                    },
                    cancel: {
                        set: values.cancel
                    },
                    week: values.week,
                    days: days?.map(object => dayjs(object).format(format)) || [],
                    closes: closes?.map(object => dayjs(object).format(format)) || [],
                    startAt: {
                        set: values.startAt
                    },
                    endAt: {
                        set: values.endAt
                    },
                    fullday: {
                        set: values.fullday
                    },
                    weightSort: {
                        set: values.weightSort
                    },
                    visible: {
                        set: values.visible
                    },
                }
            }
        })
    }

    const onDelete = async () => {
        if (id) {
            await deleteOneScheduleMutation({
                variables: {
                    where: {
                        id
                    },
                    data: {
                        status: {
                            set: false
                        },
                    }
                }
            })
        }
    }

    const onChangeStartAt = (event) => {
        const maskedValue = timeMask.resolve(event.target.value)
        form.setFieldValue('startAt', maskedValue)
    }

    const onChangeEndAt = (event) => {
        const maskedValue = timeMask.resolve(event.target.value)
        form.setFieldValue('endAt', maskedValue)
    }

    return (
        <>
            <a href="javascript:history.back()">Назад в список графиков</a>
            <Form form={form} onFinish={handleSubmit} layout='vertical'>
                <Form.Item rules={[rules.required]} name='name' label='Название графика'>
                    <Input placeholder='Введите название' />
                </Form.Item>
                <Form.Item rules={[rules.required]} name='price' label='Цена'>
                    <InputNumber style={{ width: '100%' }} placeholder='Введите цену' />
                </Form.Item>
                <Form.Item rules={[rules.required]} name='reserve' label='Цена брони'>
                    <InputNumber style={{ width: '100%' }} placeholder='Введите цену' />
                </Form.Item>
                <Form.Item name='discount' label='Скидочная цена'>
                    <InputNumber style={{ width: '100%' }} placeholder='Введите цену' />
                </Form.Item>
                <Form.Item name='cancel' label='Цена отмены'>
                    <InputNumber style={{ width: '100%' }} placeholder='Введите цену' />
                </Form.Item>
                <Form.Item name='week' label='Дни недели'>
                    <Select mode='multiple' options={week} placeholder='Выберите дни недели' optionFilterProp='label' />
                </Form.Item>
                <Form.Item label='Определенные дни'>
                    <DatePicker multiple placeholder='Выберите дни' value={days} onChange={(value) => setDays(value)} format={format} />
                </Form.Item>
                <Form.Item label='Закрыть брони'>
                    <DatePicker multiple placeholder='Выберите дни' value={closes} onChange={(value) => setCloses(value)} format={format} />
                </Form.Item>
                <Form.Item rules={[rules.required]} name='startAt' label='Время от'>
                    <Input onChange={onChangeStartAt} placeholder='Введите время' />
                </Form.Item>
                <Form.Item rules={[rules.required]} name='endAt' label='Время до'>
                    <Input onChange={onChangeEndAt} placeholder='Введите время' />
                </Form.Item>
                <Form.Item name='fullday' label='Полный день'>
                    <Switch />
                </Form.Item>
                <Form.Item name='weightSort' label='Порядковый номер в списке'>
                    <InputNumber style={{ width: '100%' }} placeholder='Введите номер' />
                </Form.Item>
                <Form.Item name='visible' label='Показывать тариф'>
                    <Switch />
                </Form.Item>
                <Button loading={updateOneScheduleLoading} type='primary' htmlType='submit'>
                    Сохранить
                </Button>
                <Popconfirm title='Удалить?' onConfirm={onDelete}>
                    <Button type='primary' danger style={{ marginLeft: 5 }}>
                        Удалить
                    </Button>
                </Popconfirm>
            </Form>
        </>
    )
}

export default Schedule

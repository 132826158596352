import React from 'react'

import styled from 'styled-components'
import { Form as AntForm, Button, message, Input, Popconfirm, InputNumber, Select, Switch } from 'antd'
import { useMutation, useQuery } from '@apollo/client'
import { useParams, useNavigate } from 'react-router-dom'
import { UPDATE_ONE_PROMOTION, FIND_UNIQUE_PROMOTION, DELETE_ONE_PROMOTION, FIND_MANY_OBJECT, FIND_MANY_TOUR, FIND_MANY_POST } from '../gqls'

const Form = styled(AntForm)`
    max-width: 600px;
`

const Promotion = () => {
    const navigate = useNavigate()
    const [form] = Form.useForm()
    const { id } = useParams()
    useQuery(FIND_UNIQUE_PROMOTION, {
        variables: {
            where: {
                id
            }
        },
        onCompleted: ({ findUniquePromotion }) => {
            form.setFieldsValue({
                name: findUniquePromotion.name,
                weight: findUniquePromotion.weight,
                objects: findUniquePromotion.objects,
                tours: findUniquePromotion.tours,
                posts: findUniquePromotion.posts,
                status: findUniquePromotion.status
            })
        }
    })

    const { data: findManyObjectData, loading: findManyObjectLoading, error: findManyObjectError } = useQuery(FIND_MANY_OBJECT, {
        fetchPolicy: 'network-only'
    })

    const { data: findManyTourData, loading: findManyTourLoading, error: findManyTourError } = useQuery(FIND_MANY_TOUR, {
        fetchPolicy: 'network-only'
    })

    const { data: findManyPostData, loading: findManyPostLoading, error: findManyPostError } = useQuery(FIND_MANY_POST, {
        fetchPolicy: 'network-only'
    })

    const [updateOnePromotionMutation, { loading: updateOnePromotionLoading }] = useMutation(UPDATE_ONE_PROMOTION, {
        onCompleted: () => {
            message.success('Данные обновлены')
        },
        onError: e => {
            message.error('Что то пошло не так, повторите попытку позже')
        }
    })

    const [deleteOnePromotionMutation] = useMutation(DELETE_ONE_PROMOTION, {
        onCompleted: () => {
            message.success('Компания удалена')
            navigate(-1)
        },
        onError: (err) => {
            console.error(err)
            message.error('Не удалось удалить')
        },
    })

    if (findManyObjectLoading || findManyTourLoading || findManyPostLoading || findManyObjectError || findManyTourError || findManyPostError) return null

    const { findManyObject } = findManyObjectData
    const { findManyTour } = findManyTourData
    const { findManyPost } = findManyPostData

    const objects = findManyObject.map(object => ({
        label: object.name,
        value: object.id
    }))

    const tours = findManyTour.map(object => ({
        label: object.name,
        value: object.id
    }))

    const posts = findManyPost.map(object => ({
        label: object.user,
        value: object.id
    }))

    const handleSubmit = async (values) => {
        await updateOnePromotionMutation({
            variables: {
                where: {
                    id
                },
                data: {
                    name: {
                        set: values.name
                    },
                    weight: {
                        set: values.weight || 0
                    },
                    objects: values.objects,
                    tours: values.tours,
                    posts: values.posts,
                    status: values.status
                }
            }
        })
    }

    const onDelete = async () => {
        if (id) {
            await deleteOnePromotionMutation({
                variables: {
                    where: {
                        id
                    }
                }
            })
        }
    }

    return (
        <>
            <Form form={form} onFinish={handleSubmit} layout='vertical'>
                <Form.Item name='name' label='Название подборки'>
                    <Input placeholder='Введите название' />
                </Form.Item>
                <Form.Item name='weight' label='Вес'>
                    <InputNumber style={{ width: '100%' }} placeholder='Введите вес' />
                </Form.Item>
                <Form.Item name='objects' label='Обьекты'>
                    <Select
                        mode='multiple'
                        placeholder='Выберите обьекты'
                        optionFilterProp='label'
                        options={objects}
                    />
                </Form.Item>
                <Form.Item name='tours' label='Туры'>
                    <Select
                        mode='multiple'
                        placeholder='Выберите туры'
                        optionFilterProp='label'
                        options={tours}
                    />
                </Form.Item>
                <Form.Item name='posts' label='Посты'>
                    <Select
                        mode='multiple'
                        placeholder='Выберите посты'
                        optionFilterProp='label'
                        options={posts}
                    />
                </Form.Item>
                <Form.Item name='status' label='Cтатус'>
                    <Switch />
                </Form.Item>
                <Button loading={updateOnePromotionLoading} type='primary' htmlType='submit'>
                    Сохранить
                </Button>
                <Popconfirm title='Удалить?' onConfirm={onDelete}>
                    <Button type='primary' danger style={{ marginLeft: 5 }}>
                        Удалить
                    </Button>
                </Popconfirm>
            </Form>
        </>
    )
}

export default Promotion

import { useState, useMemo } from 'react'
import {
    Table,
    Form,
    Input
} from 'antd'
import { useQuery } from '@apollo/client'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import dayjs from 'dayjs'
import 'dayjs/locale/ru'

import Title from '../components/Title'

import { FIND_MANY_REQUEST, FIND_MANY_REQUEST_COUNT } from '../gqls'

const Filters = styled(Form)`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 20px;

    .item {
        width: 200px;
        margin-right: 15px;
    }
`

const limit = 10

const Reviews = () => {
    const [currentPage, setCurrentPage] = useState(1)
    const [search, setSearch] = useState('')

    const variables = useMemo(() => ({
        where: {
            rating: {
                gte: 1
            },
            OR: [
                {
                    name: { contains: search, mode: 'insensitive' },
                },
                {
                    phone: { contains: search, mode: 'insensitive' },
                }
            ]
        }
    }), [search])

    const { data: findManyRequestData, loading: findManyRequestLoading } = useQuery(FIND_MANY_REQUEST, {
        variables: {
            ...variables,
            take: limit * currentPage,
            skip: 0,
            orderBy: {
                createdAt: 'desc'
            },
        },
        fetchPolicy: 'network-only'
    })

    const { data: findManyRequestCountData, loading: findManyRequestCountLoading } = useQuery(FIND_MANY_REQUEST_COUNT, {
        variables,
        fetchPolicy: 'network-only'
    })

    function dayTitle(number) {
        if (number < 0) return ''
        if (number > 10 && [11, 12, 13, 14].includes(number % 100)) return number + ' дней';
        var last_num = number % 10;
        if (last_num == 1) return number + ' день';
        if ([2, 3, 4].includes(last_num)) return number + ' дня';
        if ([5, 6, 7, 8, 9, 0].includes(last_num)) return number + ' дней';
    }

    const findManyRequest = findManyRequestData?.findManyRequest || []
    const findManyRequestCount = findManyRequestCountData?.findManyRequestCount || 0

    const handleChangeTable = ({ current }) => setCurrentPage(current)

    return (
        <>
            <Title
                text={`Список отзывов (${findManyRequestCount})`}
            />
            <Filters>
                <Input
                    placeholder='Поиск'
                    allowClear
                    onChange={e => setSearch(e.target.value ? e.target.value : '')}
                    className='item'
                />
            </Filters>
            <Table
                loading={findManyRequestLoading || findManyRequestCountLoading}
                rowKey={(obj) => obj.id}
                dataSource={findManyRequest}
                scroll={{ x: 600 }}
                size={window.innerWidth < 500 ? 'small' : 'large'}
                pagination={{
                    current: currentPage,
                    total: findManyRequestCount,
                    pageSize: 10
                }}
                onChange={handleChangeTable}
                columns={[
                    {
                        title: 'id',
                        dataIndex: 'id',
                        key: 'id',
                        render: (id) => <Link to={id}>{id}</Link>
                    },
                    {
                        title: 'Дата и время брони',
                        dataIndex: 'reservedAt',
                        key: 'reservedAt',
                        render: (reservedAt, object) => <span>{dayjs(reservedAt).format('DD.MM.YYYY')} {object.schedule.startAt}-{object.schedule.endAt}</span>
                    },
                    {
                        title: 'Имя',
                        dataIndex: 'name',
                        key: 'name',
                        render: (name) => <span>{name}</span>
                    },
                    {
                        title: 'Обьект',
                        dataIndex: 'object',
                        key: 'object',
                        render: (object) => <span>{object?.name || '-'}</span>
                    },
                    {
                        title: 'Оценка',
                        dataIndex: 'rating',
                        key: 'rating',
                        render: (rating) => <span>{rating}</span>
                    },
                    {
                        title: 'Дата отзыва',
                        dataIndex: 'ratingAt',
                        key: 'ratingAt',
                        render: (ratingAt) => <span>{ratingAt ? ratingAt + ' ' + dayTitle(3 - (dayjs(ratingAt).diff(dayjs(), 'day') * -1)) : '-'}</span>
                    },
                ]}

            />
        </>
    )
}

export default Reviews
import React, { useState } from 'react'

import styled from 'styled-components'
import { Form as AntForm, Button, message, Upload, Select, Input } from 'antd'
import { useMutation, useQuery } from '@apollo/client'
import { useNavigate } from 'react-router-dom'

import { FIND_MANY_ADMIN_TG_BOT, UPDATE_MANY_ADMIN_TG_BOT } from '../gqls'

const Form = styled(AntForm)`
    max-width: 600px;
`

const SuperAdminTgBot = () => {
    const navigate = useNavigate()
    const [form] = Form.useForm()
    const [comforts, setComforts] = useState([])

    useQuery(FIND_MANY_ADMIN_TG_BOT, {
        fetchPolicy: 'network-only',
        onCompleted: ({ findManyAdminTgBot }) => {
            const uniqueArray = findManyAdminTgBot.filter(function(item, pos) {
                return findManyAdminTgBot.indexOf(item) == pos;
            })
            const companiesData = uniqueArray.map((object, index) => ({
                value: object.company.id,
                label: object.company.name,
                key: index
            }))
            setComforts(companiesData)
        }
    })

    const [updateOneAdminMutation, { loading: updateOnePostLoading }] = useMutation(UPDATE_MANY_ADMIN_TG_BOT, {
        onCompleted: () => {
            message.success('Данные обновлены')
        },
        onError: e => {
            message.error('Что то пошло не так, повторите попытку позже')
        }
    })
    
    const handleSubmit = async (values) => {
        await updateOneAdminMutation({
            variables: {
                where: {
                    phone: {
                        equals: '79644236888'
                    },
                },
                data: {
                    companyId: {
                        set: values.companies
                    },
                }
            }
        })
    }
    
    return (
        <>
            <Form form={form} onFinish={handleSubmit} layout='vertical'>
                <Form.Item name='companies' label='Компании'>
                     <Select
                        mode='single'
                        optionFilterProp='label'
                        placeholder='Выберите из списка'
                        options={comforts}
                    />
                </Form.Item>
                <Button type='primary' htmlType='submit'>
                    Cохранить
                </Button>
            </Form>
        </>
    )
}

export default SuperAdminTgBot

import React, { useEffect, useState } from 'react'

import styled from 'styled-components'
import { Form as AntForm, Button, message, Input, Select, Popconfirm, Switch } from 'antd'
import { useMutation, useQuery } from '@apollo/client'
import { useParams, useNavigate, Link } from 'react-router-dom'
import IMask from 'imask'

import { UPDATE_ONE_COMPANY, FIND_UNIQUE_COMPANY, DELETE_ONE_COMPANY } from '../gqls'

const Form = styled(AntForm)`
    max-width: 600px;
`

const phoneMask = IMask.createMask({
    mask: '70000000000'
})

const rules = {
    required: {
        required: true,
        message: 'Обязательное поле'
    }
}

const Company = () => {
    const navigate = useNavigate()
    const [form] = Form.useForm()
    const { id } = useParams()
    const [legal, setLegal] = useState(false)
    const paymentTypes = [
        {
            label: 'Оплата по номеру телефона',
            value: 0
        },
        {
            label: 'Оплата по карте',
            value: 1
        }
    ]
    const { data: findUniqueCompanyData } = useQuery(FIND_UNIQUE_COMPANY, {
        variables: {
            where: {
                id
            }
        },
        onCompleted: ({ findUniqueCompany }) => {
            form.setFieldsValue({
                name: findUniqueCompany.name,
                phone: findUniqueCompany.phone,
                contact: findUniqueCompany.contact,
                payment: findUniqueCompany.payment,
                paymentCardNumber: findUniqueCompany.paymentCardNumber,
                paymentType: findUniqueCompany.paymentType,
                email: findUniqueCompany.email,
                legal: findUniqueCompany.legal,
                comment: findUniqueCompany.comment,
                enabledStatistic: findUniqueCompany.enabledStatistic
            })
        }
    })

    const [updateOneCompanyMutation, { loading: updateOneCompanyLoading }] = useMutation(UPDATE_ONE_COMPANY, {
        onCompleted: () => {
            message.success('Данные обновлены')
        },
        onError: e => {
            message.error('Что то пошло не так, повторите попытку позже')
        }
    })

    const [deleteOneCompanyMutation] = useMutation(DELETE_ONE_COMPANY, {
        onCompleted: () => {
            message.success('Компания удалена')
            navigate(-1)
        },
        onError: (err) => {
            console.error(err)
            message.error('Не удалось удалить')
        },
    })

    useEffect(() => {
        const findUniqueCompany = findUniqueCompanyData?.findUniqueCompany
        if (findUniqueCompany && !findUniqueCompany.splitId && legal) {
            // navigate(`RegistrationPayment`)
        }
    }, [legal])

    const handleSubmit = async (values) => {
        await updateOneCompanyMutation({
            variables: {
                where: {
                    id
                },
                data: {
                    name: {
                        set: values.name
                    },
                    contact: {
                        set: values.contact
                    },
                    payment: {
                        set: values.payment
                    },
                    paymentCardNumber: {
                        set: values.paymentCardNumber
                    },
                    paymentType: {
                        set: values.paymentType
                    },
                    email: {
                        set: values.email
                    },
                    comment: {
                        set: values.comment
                    },
                    enabledStatistic: {
                        set: values.enabledStatistic
                    },
                }
            }
        })
    }

    const onDelete = async () => {
        if (id) {
            await deleteOneCompanyMutation({
                variables: {
                    where: {
                        id
                    }
                }
            })
        }
    }

    const onChangePhoneContact = (event) => {
        const maskedValue = phoneMask.resolve(event.target.value)
        form.setFieldValue('contact', maskedValue)
    }

    const onChangePhonePayment = (event) => {
        const maskedValue = phoneMask.resolve(event.target.value)
        form.setFieldValue('payment', maskedValue)
    }
    
    return (
        <>
            <Form form={form} onFinish={handleSubmit} layout='vertical'>
                <Form.Item rules={[rules.required]} name='name' label='Название компании'>
                    <Input placeholder='Введите название' />
                </Form.Item>
                <Form.Item name='phone' label='Номер телефона'>
                    <Input disabled placeholder='Введите номер телефона' />
                </Form.Item>
                <Form.Item rules={[rules.required]} name='contact' label='Контактный номер телефона'>
                    <Input onChange={onChangePhoneContact} placeholder='Введите номер телефона' />
                </Form.Item>
                <Form.Item name='payment' label='Номер телефона для оплаты'>
                    <Input onChange={onChangePhonePayment} placeholder='Введите номер телефона' />
                </Form.Item>
                <Form.Item name='paymentCardNumber' label='Номер карты для оплаты'>
                    <Input placeholder='Введите номер карты' />
                </Form.Item>
                <Form.Item name='paymentType' label='Тип оплаты'>
                    <Select
                        placeholder='Выберите тип оплаты'
                        options={paymentTypes}
                    />
                </Form.Item>
                <Form.Item rules={[rules.required]} name='email' label='Email компании'>
                    <Input placeholder='Введите email' />
                </Form.Item>
                <Form.Item name='comment' label='Способ оплаты'>
                    <Input placeholder='Способ оплаты' />
                </Form.Item>
                <Form.Item name='legal' label='Юр. лицо'>
                    <Switch value={legal} onChange={value => setLegal(value)} />
                </Form.Item>
                <Form.Item name='enabledStatistic' label='Статистика включен в тг'>
                    <Switch />
                </Form.Item>
                
                <Button loading={updateOneCompanyLoading} type='primary' htmlType='submit'>
                    Сохранить
                </Button>
                <Popconfirm title='Удалить?' onConfirm={onDelete}>
                    <Button type='primary' danger style={{ marginLeft: 5 }}>
                        Удалить
                    </Button>
                </Popconfirm>
                <Link to='admins-tg-bot'>
                    <Button style={{ marginLeft: 5 }}>
                    Администратор в телеграме
                    </Button>
                </Link>
                <Link to='RegistrationPayment'>
                    <Button style={{ margin: 5 }}>
                        Данные для платежа
                    </Button>
                </Link>
            </Form>
        </>
    )
}

export default Company

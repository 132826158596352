import React, { useState } from 'react'

import styled from 'styled-components'
import { Form as AntForm, Button, message, Upload, Select, Input } from 'antd'
import { useMutation, useQuery } from '@apollo/client'
import { useNavigate } from 'react-router-dom'

import { CREATE_ONE_ICON, FIND_MANY_ADMIN_TG_BOT, FIND_MANY_OBJECT } from '../gqls'
import FormItemLabel from 'antd/es/form/FormItemLabel'
import TextArea from 'antd/es/input/TextArea'
import axios from 'axios'

const Form = styled(AntForm)`
    max-width: 600px;
`

const rules = {
    required: {
        required: true,
        message: 'Обязательное поле'
    }
}

const HOST_NAME = process.env.REACT_APP_HOST_NAME

const PushAdminTgBot = () => {
    const navigate = useNavigate()
    const [form] = Form.useForm()
    const [images, setImages] = useState([])
    const [comforts, setComforts] = useState([])

    useQuery(FIND_MANY_ADMIN_TG_BOT, {
        fetchPolicy: 'network-only',
        onCompleted: ({ findManyAdminTgBot }) => {
            const uniqueArray = findManyAdminTgBot.filter(function(item, pos) {
                return findManyAdminTgBot.indexOf(item) == pos;
            })
            const companiesData = uniqueArray.map((object, index) => ({
                value: object.company.id,
                label: object.company.name,
                key: index
            }))
            setComforts(companiesData)
        }
    })
    
    const handleSubmit = async (values) => {
        axios.post(HOST_NAME + 'tg-send-msg', {
                "companies": values.companies,
                "message": values.msg
            }).then(response => {

            })
    }
    
    return (
        <>
            <Form form={form} onFinish={handleSubmit} layout='vertical'>
                <Form.Item name='companies' label='Компании'>
                     <Select
                        mode='multiple'
                        optionFilterProp='label'
                        placeholder='Выберите из списка'
                        options={comforts}
                    />
                </Form.Item>
                <Form.Item name='msg' label='Текст' rules={[rules.required]}>
                    <TextArea placeholder='Введите текст' />
                </Form.Item>
                <Button type='primary' htmlType='submit'>
                    Отправить
                </Button>
            </Form>
        </>
    )
}

export default PushAdminTgBot

import React from 'react'

import styled from 'styled-components'
import { Form as AntForm, Button, message, Input, InputNumber, Select, Switch, DatePicker } from 'antd'
import { useMutation } from '@apollo/client'
import { useNavigate, useParams } from 'react-router-dom'
import IMask from 'imask'
import dayjs from 'dayjs'
import 'dayjs/locale/ru'

import { CREATE_ONE_SCHEDULE } from '../gqls'


const Form = styled(AntForm)`
    max-width: 600px;
`

const timeMask = IMask.createMask({
    overwrite: true,
    autofix: true,
    mask: 'HH:MM',
    blocks: {
        HH: {
            mask: IMask.MaskedRange,
            placeholderChar: 'HH',
            from: 0,
            to: 23,
            maxLength: 2
        },
        MM: {
            mask: IMask.MaskedRange,
            placeholderChar: 'MM',
            from: 0,
            to: 59,
            maxLength: 2
        }
    }
})

const week = [
    {
        value: 'Понедельник',
        label: 'Понедельник'
    },
    {
        value: 'Вторник',
        label: 'Вторник'
    },
    {
        value: 'Среда',
        label: 'Среда'
    },
    {
        value: 'Четверг',
        label: 'Четверг'
    },
    {
        value: 'Пятница',
        label: 'Пятница'
    },
    {
        value: 'Суббота',
        label: 'Суббота'
    },
    {
        value: 'Воскресенье',
        label: 'Воскресенье'
    }
]

const rules = {
    required: {
        required: true,
        message: 'Обязательное поле'
    }
}

const format = 'DD.MM.YYYY'

const AddSchedule = () => {
    const navigate = useNavigate()
    const [form] = Form.useForm()

    const { id } = useParams()

    const [createOneScheduleMutation, { loading: createOneScheduleLoading }] = useMutation(CREATE_ONE_SCHEDULE, {
        onCompleted: () => {
            form.resetFields()
            message.success('Пост добавлен')
            navigate(-1)
        },
        onError: e => {
            message.error('Что то пошло не так, повторите попытку позже')
        }
    })

    const handleSubmit = async (values) => {
        await createOneScheduleMutation({
            variables: {
                data: {
                    name: values.name,
                    price: values.price,
                    reserve: values.reserve,
                    discount: values.discount || undefined,
                    cancel: values.cancel || undefined,
                    week: values.week,
                    days: values?.days?.map(object => dayjs(object).format(format)) || [],
                    startAt: values.startAt,
                    endAt: values.endAt,
                    fullday: values.fullday,
                    object: {
                        connect: {
                            id
                        }
                    }
                }
            }
        })
    }

    const onChangeStartAt = (event) => {
        const maskedValue = timeMask.resolve(event.target.value)
        form.setFieldValue('startAt', maskedValue)
    }

    const onChangeEndAt = (event) => {
        const maskedValue = timeMask.resolve(event.target.value)
        form.setFieldValue('endAt', maskedValue)
    }

    return (
        <>
            <Form form={form} onFinish={handleSubmit} layout='vertical'>
                <Form.Item rules={[rules.required]} name='name' label='Название графика'>
                    <Input placeholder='Введите название' />
                </Form.Item>
                <Form.Item rules={[rules.required]} name='price' label='Цена'>
                    <InputNumber style={{ width: '100%' }} placeholder='Введите цену' />
                </Form.Item>
                <Form.Item rules={[rules.required]} name='reserve' label='Цена брони'>
                    <InputNumber style={{ width: '100%' }} placeholder='Введите цену' />
                </Form.Item>
                <Form.Item name='discount' label='Скидочная цена'>
                    <InputNumber style={{ width: '100%' }} placeholder='Введите цену' />
                </Form.Item>
                <Form.Item name='cancel' label='Цена отмены'>
                    <InputNumber style={{ width: '100%' }} placeholder='Введите цену' />
                </Form.Item>
                <Form.Item name='week' label='Дни недели'>
                    <Select mode='multiple' options={week} placeholder='Выберите дни недели' optionFilterProp='label' />
                </Form.Item>
                <Form.Item name='days' label='Определенные дни'>
                    <DatePicker multiple placeholder='Выберите дни' format={format} />
                </Form.Item>
                <Form.Item rules={[rules.required]} name='startAt' label='Время от'>
                    <Input onChange={onChangeStartAt} placeholder='Введите время' />
                </Form.Item>
                <Form.Item rules={[rules.required]} name='endAt' label='Время до'>
                    <Input onChange={onChangeEndAt} placeholder='Введите время' />
                </Form.Item>
                <Form.Item name='fullday' label='Полный день'>
                    <Switch />
                </Form.Item>
                <Button loading={createOneScheduleLoading} type='primary' htmlType='submit'>
                    Добавить
                </Button>
            </Form>
        </>
    )
}

export default AddSchedule
